<template>
  <v-card class="mb-3" outlined>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="document in documents" :key="document.id">
        <v-expansion-panel-header>
          {{ document.document.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Printer Alias"
                  :value="document.document.printer"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col v-if="!!get(document, 'scheduleDocument.file_name')">
                <v-text-field
                  label="File Name"
                  :value="document.scheduleDocument.file_name"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quantity"
                  :value="document.scheduleDocument.quantity"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";

export default {
  name: "AccordionDocuments",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const documents = computed(() => {
      return props.value
        .map((scheduleDocument) => {
          const document = props.entities.document.find(
            (document) => scheduleDocument.document_id === document.id
          );
          if (!document) return null;
          return {
            id: scheduleDocument.id,
            scheduleDocument: scheduleDocument,
            document: document,
          };
        })
        .filter((document) => !!document);
    });

    return {
      documents,
      get,
    };
  },
};
</script>
